@keyframes revealInfo {
  0% {
    max-height: 0px;
    opacity: 0.3;
  }
  100% {
    max-height: 300px;
    opacity: 1;
  }
}

.hello-there {
  display: flex;
  flex: 1;
  /* height: 100vh;
  width: 100vw; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #040303;
  animation: 1.25s ease-out 0s 1 revealInfo;
  overflow: hidden;
}

.intro-name {
  color:transparent;
  text-align: center;
  font-size: 80px;
  /* background-image:url('https://media0.giphy.com/media/3oriNZuNzeTbtJvKs8/giphy.gif'); */
  /* background-size:contain;
  background-clip: text;
  -webkit-background-clip: text; */
  color: white;
  margin-top: 20px;
  font-family: 'Bebas Neue';
}

.langlist {
  color: white;
  font-family: 'Ubuntu', sans-serif;
  display: flex;
  user-select: none;
}

.langlist-item{
  background-color: #270082;
  /* border: solid 1px white; */
  border-radius: 5px;
  padding: 5px;
  margin:5px
}

.langlist-item:hover .langlist-sub{
  display: flex;
  position: fixed;
  flex-direction: column;
}

.langlist-sub{
  display: none;
  background-color: #3E497A;
  padding: 10px;
  border-radius: 5px;
}

.langlist-sub text:not(:first-child){
  margin-top: 6px;
}

.itemgroup {
  /* margin: 3vh; */
  padding: 5px;
  display: flex;
  flex-direction: row;
  /* width: 30%; */
  margin-top: 10px;
  /* border: solid 1px grey; */
}

.item {
  /* border-width: 1px; */
  /* border-color: darkgrey; */
  /* border-style: solid; */
  margin: 10px;
  color: aliceblue;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.button {
  border: solid 1px grey;
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.button:hover{
  text-decoration: underline;
}

.button > text {
  margin-left: 4px;

}

